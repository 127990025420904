<template>
  <div id="about-container">
    <div id="top-container">
      <img id="image-logo" src="../assets/Big_logo_effingo.png" alt="Responsive image">
      <h1>Leading Technology in 3D Body Scanning</h1>
      <div
          ref="aboutRef"
          v-for="(item, index) in aboutList"
          :key="index"
      >
        <v-sheet
            :class="[
              `about-us-${index}`
            ]"
            elevation="16"
            rounded="xl"
            class="mx-auto"
            height="auto"
            width="100%"
            color="#666666"
        >
          <div :class="`text-container container-${index}`">
            <h1 :style="{ color: item.color }">{{ item.title }}</h1>
            <div id="container-divider">
              <v-divider></v-divider>
            </div>
            <h2 :style="{ color: item.color }" v-html="item.description"></h2>
          </div>
        </v-sheet>
      </div>
    </div>
    <v-carousel
        v-model="model"
        hide-delimiters
        cycle
    >
      <v-carousel-item
          v-for="(itemCard, indexCard) in itemCard"
          :key="indexCard"
      >
        <v-card
            class="mx-auto card-container"
            max-width="800"
            height="100%"
            :style="{
              backgroundImage: `url(${itemCard.background})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }"
            :href="itemCard.link"
            target="_blank"
        >
          <div class="card-content">
            <div class="text-content">
              <v-card-title class="card-title" :style="{ color: itemCard.color }">{{ itemCard.title }}</v-card-title>
              <v-card-subtitle class="card-subtitle" :style="{ color: itemCard.color }">{{
                  itemCard.description
                }}
              </v-card-subtitle>
            </div>
          </div>
        </v-card>

      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
import modaImage from '@/assets/useCasesPage/moda.jpg';
import sportImage from '@/assets/useCasesPage/sport.jpg';
import videogameImage from '@/assets/useCasesPage/games.jpg';
import medicaleImage from '@/assets/useCasesPage/medical.jpg';
import bodytechImage from '@/assets/aboutUs/3dbodytech.jpg';

export default {
  name: 'AboutUs',
  data() {
    return {
      model: 0,
      aboutList: [
        {
          color: '#ffffff',
          align: 'left',
          title: 'About Us',
          description: 'Effingo, a spin-off company where University of Bologna is equity partner. <br>' +
              ' Our goal is to create a 3D scanning system that can produce highly accurate and realistic reconstructions of objects and specially people.' +
              'We’re focused on delivering advanced technology that makes it easy and accessible to generate 3D avatars and models, all while maintaining high standards of precision and visual quality. ' +
              '<br>' +
              'This opens up exciting new possibilities in industries like fashion, entertainment, and healthcare, where capturing precise details is crucial.',
          background: modaImage,
        },
        {
          color: '#ffffff',
          align: 'right',
          title: 'Our Mission',
          description: 'At Effingo, our mission is to connect your body to the digital future.<br>' +
              'Our 3D measurement platform offers a completely personalized experience, guiding our world toward a more connected future.',
          background: sportImage,
        }
      ],
      itemCard: [
        {
          color: '#000000',
          align: 'left',
          title: '3DBODY.TECH',
          description: 'The Premier Multidisciplinary International Conference and Exhibition on 3D Human Body Scanning and Processing Technologies',
          background: bodytechImage,
          link: 'https://3dbody.tech/'
        }
      ],
      isVisible: Array(4).fill(false) // Store visibility state for each v-sheet
    };
  },
  mounted() {
    this.setupIntersectionObserver();
  },
  methods: {
    setupIntersectionObserver() {
      const options = {
        threshold: 0.5, // Trigger when 50% of the v-sheet is visible
      };

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          const index = entry.target.getAttribute('data-index');
          if (entry.isIntersecting) {
            this.$set(this.isVisible, index, true); // Make the v-sheet visible
          }
        });
      }, options);

      // Observe each v-sheet
      this.$refs.useCaseRef.forEach((useCase, index) => {
        useCase.setAttribute('data-index', index);
        observer.observe(useCase);
      });
    },
  },
};
</script>

<style scoped>
#about-container {
  background-color: black;
  padding: 8%;
}

#image-logo {
  width: 40vw;
  margin-top: 5%;
}

h1 {
  color: white;
}

.v-divider {
  padding: 0.5%;
  background-color: white;
  margin: 2.5% 35% 2.5% 35% !important;
}

.container-0 {
  margin-top: 10%;
}

.v-sheet {
  margin-bottom: 10%;
}

.about-us-3 {
  margin-bottom: 0;
}

.text-container {
  padding: 5%;
}

.card-content {
  background-color: rgba(255, 255, 255, 0.68);
  width: 100%;
  position: absolute;
  bottom: 0;
}

.card-title {
  color: white;
  font-size: 28px;
}

.card-subtitle {
  color: white;
  font-size: 20px;
}

#top-container {
  text-align: center;
}

/* Slide-in animations */
@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-enter-active {
  animation: slideInLeft 1s ease-out forwards;
}

.slide-enter-right-active {
  animation: slideInRight 1s ease-out forwards;
}

.hidden {
  opacity: 0;
  transform: translateX(-100%);
}

@media only screen and (max-width: 600px) {
  #image-logo {
    width: 90vw;
    margin-top: 100px;
    margin-left: -15px;
  }

  #use-cases-container {
    margin-top: 15% !important;
  }
}

@media only screen and (min-width: 600px) {
  #test {
    margin-top: 8% !important;
  }
}

@media only screen and (min-width: 768px) {
  #test {
    margin-top: 8% !important;
  }
}

@media only screen and (min-width: 992px) {
  #test {
    margin-top: 4% !important;
  }
}

@media only screen and (min-width: 1200px) {
  #test {
    margin-top: 0 !important;
  }
}

@media only screen and (min-width: 2000px) {
  #test {
    margin-top: 0 !important;
  }

}
</style>
