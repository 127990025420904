import Vue from 'vue'
import App from './App.vue'
import router from './router.js'
import vuetify from './plugins/vuetify';

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import inViewportDirective from 'vue-in-viewport-directive'
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'


Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueToast);
Vue.directive('in-viewport', inViewportDirective)


new Vue({
  router: router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
