<template>
  <v-app>
    <v-main>
      <AppBar></AppBar>
      <!-- <keep-alive> -->
      <router-view></router-view>
      <!-- </keep-alive> -->
      <Footer></Footer>
    </v-main>
  </v-app>
</template>

<script>
import AppBar from './components/AppBar.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    AppBar,
    Footer
  },
  data: () => ({
    //
  }),
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=RocknRoll+One&display=swap");

#app {
  font-family: 'RocknRoll One', sans-serif;
  overflow-x: hidden;
}
</style>
