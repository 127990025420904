<template>
  <div id="timeline-container">
    <h1>Use Cases</h1>
    <v-carousel
        cycle
        height="600"
        hide-delimiter-background
        show-arrows-on-hover
    >
      <v-carousel-item
          v-for="(item, i) in items"
          :key="i"
          :src="item.background"
      >
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script setup>

const items = [
  {
    color: 'cyan',
    title: 'Moda',
    description: 'test',
    icon: 'mdi-star',
    background: 'https://cdn.vuetifyjs.com/images/parallax/material.jpg'
  },
  {
    color: 'green',
    title: 'Sport',
    description: 'test',
    icon: 'mdi-book-variant',
    background: 'https://cdn.vuetifyjs.com/images/parallax/material.jpg'
  },
  {
    color: 'pink',
    title: 'Medicale',
    description: 'test',
    icon: 'mdi-airballoon',
    background: 'https://cdn.vuetifyjs.com/images/parallax/material.jpg'
  }
]
</script>

<script>
export default {
  data: () => ({
    items: [
      {
        color: 'cyan',
        title: 'Moda',
        description: 'test',
        icon: 'mdi-star',
        background: 'https://cdn.vuetifyjs.com/images/parallax/material.jpg'
      },
      {
        color: 'green',
        title: 'Sport',
        description: 'test',
        icon: 'mdi-book-variant',
        background: 'https://cdn.vuetifyjs.com/images/parallax/material.jpg'
      },
      {
        color: 'pink',
        title: 'Medicale',
        description: 'test',
        icon: 'mdi-airballoon',
        background: 'https://cdn.vuetifyjs.com/images/parallax/material.jpg'
      }
    ],
  }),
}
</script>

<style scoped>
#timeline-container {
  padding: 4%;
  margin-top: 6%;
  border-radius: 25px;
  background-color: #666666 !important;
}

h1 {
  color: white;
  text-align: center;
  margin-bottom: 4%;
}

.theme--light.v-timeline:before {
  background: white;
}

.v-card {
  //background-image: url("https://cdn.vuetifyjs.com/images/parallax/material.jpg") !important;
  width: 1000px;
  height: 500px;
  background-size: cover !important; /* Ensures background covers the card */
  background-position: center !important; /* Centers the image */
}
</style>