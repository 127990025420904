<template>
  <b-navbar :class="{'scrolled': isScrolled}" toggleable="lg" type="transparent" class="header" fixed="top">
    <b-navbar-brand href="#">
      <router-link class="hover hover-4" to="/">
        <img id="app-bar-logo" :class="{'scrolled-logo': isScrolled}" src="../assets/LogoEffingoTransparent.png"
             alt="Responsive image">
      </router-link>
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse">
      <b-icon id="list-icon" icon="list" font-scale="2"></b-icon>
    </b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav id="nav-links-container" class="ml-auto">
        <b-nav-item class="nav-link-item">
          <router-link class="hover hover-4" to="/">Home</router-link>
        </b-nav-item>
        <b-nav-item class="nav-link-item">
          <router-link class="hover hover-4" to="/usecases">Use Cases</router-link>
        </b-nav-item>
<!--        <b-nav-item class="nav-link-item">-->
<!--          <router-link class="hover hover-4" to="/contatti">Contact Us</router-link>-->
<!--        </b-nav-item>-->
        <b-nav-item class="nav-link-item">
          <router-link class="hover hover-4" to="/about">About Us</router-link>
        </b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
export default {
  data() {
    return {
      isScrolled: false
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.isScrolled = window.scrollY > 100;
    }
  }
}
</script>

<style lang="scss" scoped>
#app-bar-logo {
  width: 22vw; /* Default size */
  transition: width 0.3s ease; /* Smooth transition on resizing */
}

/* When scrolled, reduce the width of the logo */
.scrolled-logo {
  width: 10vw !important; /* New width after scrolling */
}

/* Default navbar background */
.header {
  background-color: transparent;
  transition: background-color 0.3s ease;
}

/* Navbar background when scrolled */
.scrolled {
  background-color: rgba(0, 0, 0, 0.8); /* Add background color when scrolled */
}

nav {
  padding: 1rem;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  #app-bar-logo {
    margin-left: 10px;
    width: 55vw;
  }
  .scrolled-logo {
    width: 30vw !important; /* Reduced width for smaller screens */
  }
  /* Default navbar background */
  .header {
    background-color: black !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  #app-bar-logo {
    width: 35vw;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  #app-bar-logo {
    width: 30vw;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  #app-bar-logo {
    width: 28vw;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  #app-bar-logo {
    width: 22vw;
  }
}

$animate: all 0.2s ease-in-out;

#nav-links-container {
  .nav-link-item {
    .hover {
      font-size: 1em;
      text-align: center;
      margin: 0px 1vw;
      padding: 0;
      transition: $animate;
      text-decoration: none;
      color: white;
      position: relative;

      &:before,
      &:after {
        content: "";
        position: absolute;
        bottom: -10px;
        width: 0px;
        height: 2px;
        margin: 5px 0 0;
        transition: $animate;
        transition-duration: 0.75s;
        opacity: 0;
        background-color: #10bccd;
      }

      &.hover-4 {
        &:before {
          left: 0;
        }

        &:after {
          right: 0;
        }
      }
    }

    &:hover {
      cursor: pointer;

      .hover {
        &:before,
        &:after {
          width: 100%;
          opacity: 1;
        }

        &.hover-3,
        &.hover-4 {
          &:before,
          &:after {
            width: 50%;
          }
        }
      }
    }
  }
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
}

#toggle-button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #10bccd;
}

#toggle-button:focus, #toggle-button:active {
  outline: none !important;
  box-shadow: none;
  background-color: #10bccd;
}

#list-icon {
  color: white;
}
</style>
