<template>
    <div id="home-card-container" class="container">
       
         <b-row v-if="(!isMobile) && (index % 2 == 1)" class="home-card-row">
           <b-col class="home-card-cols" cols="12"  sm="6" md="6" lg="6" xl="6" align="center" >
                <h2>{{title.toUpperCase()}}</h2>
                <p>{{description}}</p>
            </b-col>
            <b-col class="home-card-cols" cols="12" sm="6" md="2" lg="2" xl="2"  offset-lg="2" align="center">
                <div class="img-hover-zoom">
                    <img :src="require(`../assets/standardHomeCard/${imageUrl}`)">
                </div>                   
            </b-col>
        </b-row>

        <b-row v-else class="home-card-row">
            <b-col class="home-card-cols" cols="12" sm="6" md="2" lg="2" xl="2" align="center" >
                <div class="img-hover-zoom">
                    <img :src="require(`../assets/standardHomeCard/${imageUrl}`)">
                </div>        
            </b-col>
            <b-col class="home-card-cols" cols="12"  sm="6" md="6" lg="6" xl="6" offset-md="4" align="center">
                <h2>{{title.toUpperCase()}}</h2>
                <p>{{description}}</p>
            </b-col>
        </b-row>
        
    </div>
</template>

<script>
export default {
    props: {
        isMobile: Boolean,
        index: Number,
        title: String,
        description: String,
        imageUrl: String
    },
    mounted:function(){
       console.log(this.index)
    }
}
</script>

<style scoped>
    #home-card-container{
        padding: 2%;
    }
    .home-card-row{
        padding: 2% 0;
    }
    .home-card-cols{
        margin-top:2%;
    }

.img-hover-zoom {
  width: 300px;
  height: auto; /* Modify this according to your need */
  overflow: hidden; /* Removing this will break the effects */
}
.img-hover-zoom img {
  transform-origin: 50% 65%;
  transition: transform 5s, filter 3s ease-in-out;
  /* filter: brightness(75%); */
}
.img-hover-zoom:hover img{
  filter: brightness(100%);
  transform: scale(1.1);
}

@media only screen and (min-width: 1024px) {
    h2{
        margin-bottom: 2vh;
    }
}

@media only screen and (max-width: 600px) {
    
    .img-hover-zoom img {
        width: 125px;
    }
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
    
    .img-hover-zoom img {
     width: 200px;
    }
    
}

</style>