<template>
  <div id="platform-container" ref="webSiteHomeCardContainer" align="center">
    <h1><strong>Effingo Platform</strong></h1>
    <b-row id="row-container">
      <b-col
          v-for="(item, index) in websiteData"
          :key="index"
          class="col"
          :class="{'is-visible': isVisible}"
          :style="{ 'transition-delay': `${index * 0.6}s` }"
      >
        <img
            id="website-card-img"
            :src="require(`../assets/webSiteCardImg/${item.imageUrl}`)"
            alt=""
            class="platform-img"
        />
        <h4>
          <b-icon class="title-icon" :icon=item.icon></b-icon>
          {{ item.title }}
        </h4>
        <h5>{{ item.description }}</h5>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    websiteData: [
      {
        icon: "eye",
        title: "Look at your avatar",
        description: "Explore your digital twin in every detail",
        imageUrl: "avatar_a.png",
      },
      {
        icon: "rulers",
        title: "Find out your measurements",
        description: "View your precise body measurements with a simple touch",
        imageUrl: "avatar_measure_a.png",
      },
      {
        icon: "file-person",
        title: "Virtual try-on",
        description: "Find the perfect outfit without worrying about wrong sizes",
        imageUrl: "avatar_wardrobe_a.png",
      }
    ],
    isVisible: false,
  }),

  mounted() {
    // Function to determine device type or screen size
    const isMobile = window.innerWidth <= 768; // Adjust this breakpoint as needed
    const isTablet = window.innerWidth > 768 && window.innerWidth <= 1024;

    // Set the threshold based on device type
    let threshold = 0.5; // Default for desktop
    let rootMargin = '0px 0px -600px 0px'
    if (isMobile) {
      threshold = 0.2; // Less threshold for mobile
      rootMargin = '0px 0px -200px 0px'
    } else if (isTablet) {
      threshold = 0.3; // Adjust for tablet
      rootMargin = '0px 0px -400px 0px'
    }

    // Configure the IntersectionObserver with a threshold and rootMargin
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.isVisible = true; // Trigger animation when visible
          observer.unobserve(entry.target); // Stop observing once it's visible
        }
      });
    }, {
      threshold: 0.2, // Trigger when 50% of the element is visible
      rootMargin: rootMargin // Adjust the margin to trigger earlier/later
    });

    // Observe the main container
    observer.observe(this.$refs.webSiteHomeCardContainer);
  },
};
</script>

<style scoped>
#platform-container {
  background-color: #666666 !important;
  padding: 4% 8% 4% 8%;
  margin-top: 6%;
  border-radius: 25px;
}

#row-container {
  margin-top: 2.5%;
}

.title-icon {
  margin-right: 2%;
}

h1, h4 {
  color: #eeeeee;
}

h5 {
  color: #000000;
}

.col {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 0.5s ease, transform 0.5s ease;
  visibility: hidden;
  margin-top: 2%;
}

.is-visible {
  opacity: 1;
  transform: translateX(0);
  visibility: visible; /* Quando l'elemento diventa visibile */
}

.platform-img {
  margin-bottom: 5%;
}

@media only screen and (max-width: 600px) {
  #platform-container {
    padding: 8%;
  }

  #row-container {
    margin-top: 8%;
  }

  h1 {
    font-size: 2rem;
  }

  h6 {
    margin-bottom: 5vh;
  }

  #website-card-img {
    width: 250px;
  }

  .col {
    margin-bottom: 8%;
  }
}

@media only screen and (min-width: 600px) {
  h1 {
    font-size: 2rem;
  }

  #website-card-img {
    width: 200px;
  }
}


/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  #website-card-img {
    width: 200px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  #website-card-img {
    width: 300px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  #website-card-img {
    width: 350px;
  }
}
</style>
