<template>
  <v-stepper id="configurator-container" v-model="currentStep">
    <v-stepper-header id="stepper-header">
      <v-stepper-step :complete="currentStep > 1" step="1" color="#10bccd">{{
        selectedStepName.first
      }}</v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="currentStep > 2" step="2" color="#10bccd">{{
        selectedStepName.second
      }}</v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="3" color="#10bccd">{{
        selectedStepName.third
      }}</v-stepper-step>
    </v-stepper-header>

    <v-stepper-items class="mb-4">
      <v-stepper-content step="1">
        <v-card class="py-10" color="#9696961a" elevation="0" height="auto">
          <b-row align-h="center">
            <b-col
              v-for="(item, index) in firstStepData"
              :key="index"
              :ref="index"
              cols="12"
              sm="6"
              md="4"
              lg="3"
              xl="3"
              align="center"
              :id="'first-step-item-' + index"
              class="mb-6"
              @click="firstItemSelect(index)"
            >
              <v-badge
                :value="item.isSelected"
                bordered
                color="#10bccd"
                icon="mdi-check"
                overlap
              >
                <img
                  :src="require(`../assets/configurator/${item.imageUrl}`)"
                  class="item-img mb-3"
                />
                <h4 class="item-name">{{ item.name }}</h4>
                <h6 class="item-start-from">a partire da €{{ item.price }}</h6>
              </v-badge>
            </b-col>
          </b-row>
        </v-card>
      </v-stepper-content>

      <v-stepper-content step="2">
        <v-card class="py-10" color="#9696961a" elevation="0" height="auto">
          <b-row align-h="center">
            <b-col
              v-for="(item, index) in secondStepData.filter(x => x.type == formData.firstSelection.index)"
              :key="index"
              :ref="index"
              cols="12"
              sm="6"
              md="4"
              lg="3"
              xl="3"
              align="center"
              :id="'second-step-item-' + index"
              class="mb-6"
              @click="secondItemSelect(index)"
            >
              <v-badge
                :value="item.isSelected" color="#10bccd"
                icon="mdi-check"
                overlap
              >
                <img
                  :src="require(`../assets/configurator/${item.imageUrl}`)"
                  class="item-img mb-3"
                />
                <h4 class="item-name">{{ item.name }}</h4>
                <h6 class="item-start-from">a partire da €{{ item.price }}</h6>
              </v-badge>
            </b-col>
          </b-row>
        </v-card>
      </v-stepper-content>

      <v-stepper-content step="3">
        <v-card class="py-10" color="#9696961a" elevation="0" height="auto">
          <b-row align="center">
            <b-col
              v-for="(item, index) in thirdStepData"
              :key="index"
              :ref="index"
              cols="12"
              sm="6"
              md="4"
              lg="4"
              xl="4"
              :id="'third-step-item-' + index"
              class="mb-6"
            >
              <!-- <v-switch
                inset
                color="#10bccd"
                align="center"
                :label="item.name"
              ></v-switch> -->
              <input
                v-model="formData.thirdSelection[index]"
                type="checkbox"
                :id="index"
              /><label :for="index"></label>
              <h5 class="item-name">{{ item.name }}</h5>
              <h6 class="item-description">{{ item.description }}</h6>
              <h6 class="item-start-from">a partire da €{{ item.price }}</h6>
            </b-col>
          </b-row>
        </v-card>
      </v-stepper-content>
    </v-stepper-items>
    <div id="buttons-container">
      <v-btn
        id="back-button"
        v-if="currentStep > 1"
        text
        @click="onBackButtonPressed"
        >Indietro</v-btn
      >
      <v-btn
        :disabled="!isButtonActive"
        color="primary"
        @click="onContinueButtonPressed"
        id="continue-button"
        >Continua</v-btn
      >
    </div>
  </v-stepper>
</template>

<script>
// import {checkIsMobile} from "../utils"

export default {
  data: () => ({
    currentStep: 1,
    isButtonActive: false,
    standardStepName: {
      first: "Servizio",
      second: "Tipologia",
      third: "Funzionalità",
    },
    selectedStepName: null,
    formData: {
      firstSelection: {
        index: null,
        name: null
      },
      secondSelection: {
        index: null,
        name: null
      },
      thirdSelection: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false                            
      },
    },
    firstStepData: [
      {
        name: "Restyling",
        price: 299,
        imageUrl: "restyling.svg",
        isSelected: false,
      },
      {
        name: "Nuovo Sito",
        price: 499,
        imageUrl: "website.svg",
        isSelected: false,
      },
      {
        name: "E-Commerce",
        price: 1799,
        imageUrl: "ecommerce.svg",
        isSelected: false,
      },
    ],
    secondStepData: [
      {
        name: "Sito presentazione aziendale",
        imageUrl: "sito-aziendale.svg",
        price: 0,
        isSelected: false,
        type: 1,
      },
      {
        name: "Blog",
        imageUrl: "blog.svg",
        price: 0,
        isSelected: false,
        type: 1,
      },
      {
        name: "Webapp",
        imageUrl: "webapp.svg",
        price: 0,
        isSelected: false,
        type: 1,
      },
      {
        name: "Landing page o minisito",
        imageUrl: "landing-page.svg",
        price: 0,
        isSelected: false,
        type: 1,
      },
      {
        name: "Altro",
        imageUrl: "altro.svg",
        price: 0,
        isSelected: false,
        type: 1,
      },
      {
        name: "E-Commerce con vendita online",
        imageUrl: "online-shopping.svg",
        price: 0,
        isSelected: false,
        type: 2,
      },
      {
        name: "E-Commerce senza vendita online",
        imageUrl: "online-shopping-no-buy.svg",
        price: 0,
        isSelected: false,
        type: 2,
      },
    ],
    thirdStepData: [
      {
        name: "Legge Cookies e Privacy",
        description: "A norma con le attuali leggi",
        price: 0,
        isSelected: false,
      },
      {
        name: "Dominio e Spazio Web",
        description: "Registrazione nome dominio + hosting",
        price: 0,
        isSelected: false,
      },
      {
        name: "Email aziendale personalizzata",
        description: "5 email personalizzate professionali",
        price: 0,
        isSelected: false,
      },
      {
        name: "Realizzazione Logo",
        description: "Realizziamo il tuo logo",
        price: 0,
        isSelected: false,
      },
      {
        name: "Creazione pagine social",
        description: "creiamo le tue pagine social",
        price: 0,
        isSelected: false,
      },
    ],
  }),
  created() {
    window.scrollTo(0, 0);
    this.selectedStepName = {...this.standardStepName}
  },
  mounted() {
    //sostituito con la chat tidio
    // setTimeout(() => {
    //    this.$toast.open({
    //     message: 'Clicca qui per contattarci direttamente',
    //     position: checkIsMobile() == true ? "bottom" : "bottom-right",
    //     type: 'info',
    //     duration: 0,
    //     onClick: this.goToContacts,
    //   });
    //   document.getElementsByClassName('v-toast__item')[0].style.backgroundColor = "#10bccd";
    // },3000)
  },
  beforeDestroy() {
    // this.$toast.clear();
  },
  methods: {
    firstItemSelect(index) {
      this.firstStepData.forEach((e, i) => {
        if (i == index) {
          e.isSelected = this.isButtonActive = !e.isSelected;
          this.selectedStepName.first = e.name;

          //to save selection at this step
          if(e.isSelected){
            this.formData.firstSelection.index = index
            this.formData.firstSelection.name= e.name
            
          }
          else this.selectedStepName.first = this.standardStepName.first
        } else e.isSelected = false;
      });
      //   var item = this.$refs[index]; //come selezionare elemento DOM
    },
    secondItemSelect(index) {
      this.secondStepData.filter(el => el.type == this.formData.firstSelection.index).forEach((e, i) => {
        if (i == index) {
          e.isSelected = this.isButtonActive = !e.isSelected;
          this.selectedStepName.second = e.name

          //to save selection at this step
          if(e.isSelected){
            this.formData.secondSelection.index = index
            this.formData.secondSelection.name= e.name
            
          }
          else this.selectedStepName.second = this.standardStepName.second;
        } else e.isSelected = false;
      });
      //   var item = this.$refs[index]; //come selezionare elemento DOM
    },
    onContinueButtonPressed() {
      switch (this.currentStep) {
        case 1:
          
          if(this.formData.firstSelection.index == 0){
            this.currentStep += 2
            this.isButtonActive = true;
          }
          else {
            this.currentStep++;
            this.isButtonActive = false;
          }
          
          break;
        case 2:
          this.currentStep++;
          break;
        case 3:
          this.createJSONToSubmit();
          break;
      }
    },
    onBackButtonPressed() {
      this.currentStep--;
      this.isButtonActive = true;
    },
    createJSONToSubmit() {
      var thirdSelections = [];


      Object.values(this.formData.thirdSelection).forEach( (el,index) => {
        if (el) thirdSelections.push(this.thirdStepData[index].name);
      });

      var params = {};
      params["type"] = this.formData.firstSelection.name;
      params["implementation"] = this.formData.secondSelection.name != null ? this.formData.secondSelection.name : this.formData.firstSelection.name;
      params["features"] = thirdSelections;
      // this.$emit("configuration-complete",json);
      // this.$router.push({ path: '/contatti', name:"Contacts",query:{'service':"Configurazione Sito Web"}, params: json })
      this.goToContacts(params);
    },

    goToContacts(params) {
      this.$router.push({
        path: "/contatti",
        name: "Contacts",
        query: { service: "Configurazione Sito Web" },
        params: params,
      });
    },
  },
};
</script>

<style scoped>
#configurator-container {
  padding: 2% 10%;
}

#stepper-header {
  background-color: #9696961a;
  box-shadow: none;
}
.item-img {
  width: 200px;
}

input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
}

label {
  cursor: pointer;
  text-indent: -9999px;
  width: 75px;
  height: 37.5px;
  background: grey;
  display: block;
  border-radius: 100px;
  position: relative;
}

label:after {
  content: "";
  position: absolute;
  top: 2.5px;
  left: 2.5px;
  width: 32.5px;
  height: 32.5px;
  background: #fff;
  border-radius: 100px;
  transition: 0.3s;
}

input:checked + label {
  background: #10bccd;
}

input:checked + label:after {
  left: calc(100% - 2.5px);
  transform: translateX(-100%);
}

label:active:after {
  width: 50px;
}

#buttons-container {
  width: 100%;
  padding: 0 10%;
}

#continue-button {
  background-color: #10bccd!important;
  float: right;
}

#back-button {
  float: left;
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
  #configurator-container {
    padding: 2% 5%;
  }
  .item-img {
    width: 150px;
  }
}

@media only screen and (max-width: 600px) {
  #configurator-container {
    padding: 0 0 5% 0;
  }
  .item-img {
    width: 125px;
  }
}
</style>