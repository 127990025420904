<template>
  <div id="container-approach" ref="containerApproach" align="center">
    <h1>Our Approach</h1>
    <b-row id="row-container">
      <b-col
          v-for="(item, index) in items"
          :key="index"
          class="col"
          :class="{'is-visible': isVisible}"
          :style="{ 'transition-delay': `${index * 0.6}s` }"
      >
        <v-card
            class="card-approach"
            max-width="385"
        >

          <v-img
              :src="require(`../assets/ourApproach/${item.background}`)"
          ></v-img>


          <h3 class="card-title">{{ item.title.toUpperCase() }}</h3>

          <h4 class="card-text">{{ item.description }}</h4>

        </v-card>
      </b-col>
    </b-row>
  </div>
</template>

<script setup>
const items = [
  {
    title: 'Point Cloud',
    description: '~1 million points',
    icon: 'mdi-star',
    background: 'point_cloud-removebg.png'
  },
  {
    title: 'Mesh',
    description: '~100k faces mesh',
    icon: 'mdi-book-variant',
    background: 'mesh-removebg.png'
  },
  {
    title: 'Textured Mesh',
    description: 'Realistic textured mesh',
    icon: 'mdi-airballoon',
    background: 'textured-removebg.png'
  }
]
</script>

<script>
export default {
  data: () => ({
    items: [
      {
        color: 'cyan',
        title: 'Moda',
        description: 'test',
        icon: 'mdi-star',
        background: 'point_cloud-removebg.png'
      },
      {
        color: 'green',
        title: 'Sport',
        description: 'test',
        icon: 'mdi-book-variant',
        background: 'mesh-removebg.png'
      },
      {
        color: 'pink',
        title: 'Medicale',
        description: 'test',
        icon: 'mdi-airballoon',
        background: 'textured-removebg.png'
      }
    ],
    isVisible: false,
  }),
  mounted() {

    // Function to determine device type or screen size
    const isMobile = window.innerWidth <= 768; // Adjust this breakpoint as needed
    const isTablet = window.innerWidth > 768 && window.innerWidth <= 1024;

    // Set the threshold based on device type
    let threshold = 0.5; // Default for desktop
    let rootMargin = '0px 0px -600px 0px'
    if (isMobile) {
      threshold = 0.2; // Less threshold for mobile
      rootMargin = '0px 0px -200px 0px'
    } else if (isTablet) {
      threshold = 0.3; // Adjust for tablet
      rootMargin = '0px 0px -400px 0px'
    }

    // Configure the IntersectionObserver with a threshold and rootMargin
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.isVisible = true; // Trigger animation when visible
          observer.unobserve(entry.target); // Stop observing once it's visible
        }
      });
    }, {
      threshold: 0.2, // Trigger when 50% of the element is visible
      rootMargin: rootMargin // Adjust the margin to trigger earlier/later
    });

    // Observe the main container
    observer.observe(this.$refs.containerApproach);
  },
}
</script>

<style scoped>
#container-approach {
  background-color: #666666;
  margin-top: 6%;
  border-radius: 25px;
  padding: 4% 8% 4% 8%;
}

#row-container {
  margin-top: 2.5%;
}

h1 {
  color: white;
  text-align: center;
  //padding: 1%;
}

.v-card {
  border-radius: 25px !important;
  padding: 6%;
}

.card-approach {
  height: 100% !important;
}

.card-title {
  text-align: center;
  color: #0088cc !important;
  margin-top: 5%;
  padding: 5%;
}

.card-text {
  text-align: center;
}

.col {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 0.5s ease, transform 0.5s ease;
  visibility: hidden; /* Inizialmente nascosto */
}

.is-visible {
  opacity: 1;
  transform: translateX(0);
  visibility: visible; /* Quando l'elemento diventa visibile */
}

@media only screen and (max-width: 600px) {
  #container-approach {
    padding: 8%;
  }

  #row-container {
    margin-top: 8%;
  }

  h1 {
    font-size: 2rem;
  }

  h6 {
    margin-bottom: 5vh;
  }

  .col {
    margin-bottom: 8%;
  }

  .card-approach {
    max-width: 250px;
  }
}


@media only screen and (min-width: 600px) {
  h1 {
    font-size: 2rem;
  }

  .card-approach {
    width: 200px;
  }
  .col {
    margin-bottom: 3%;
  }
}


/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .card-approach {
    width: 200px;
  }

  .col {
    margin-bottom: 3%;
  }
  #row-container {
  margin-top: 4%;
}
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .card-approach {
    width: 300px;
  }
  #row-container {
  margin-top: 5%;
}
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .card-approach {
    width: 350px;
  }
  #row-container {
  margin-top: 5%;
}
}

</style>