<template>
  <!--  <v-parallax-->
  <!--      jumbotron-->
  <!--      height="350"-->
  <!--      src="https://cdn.vuetifyjs.com/images/parallax/material.jpg"-->
  <!--  >-->
  <div class="card-container">
    <b-row>
      <b-col
          v-for="(feature, index) in listOfFeature"
          :key="index"
          class="col mx-auto my-10 my-sm-1 my-xs-1"
          :class="{'is-visible': isVisible}"
          :style="{ 'transition-delay': `${index * 0.6}s` }"
      >
        <SmallCard
            :isMobile="isMobile"
            :isVisible="isVisible"
            :index="index"
            :icon="feature.icon"
            :title="feature.title"
            :description="feature.description"
        ></SmallCard>
      </b-col>
    </b-row>
  </div>
  <!--    <div class="card-container d-flex flex-row justify-content-around" >-->

  <!--      <div v-for="(feature, index) in listOfFeature" :key="index">-->
  <!--&lt;!&ndash;        <div id="`hidden-container${{index}}`"></div>&ndash;&gt;-->

  <!--        -->

  <!--      </div>-->

  <!--    </div>-->

  <!--  </v-parallax>-->
</template>

<script setup>
import {ref, onMounted} from 'vue';
import SmallCard from "@/components/SmallCard.vue";

// Define props directly in <script setup>
const props = defineProps({
  listOfFeature: Array,
  isMobile: Boolean
});

// Define isVisible using `ref` to make it reactive
const isVisible = ref(false);

// IntersectionObserver logic inside `onMounted` (Composition API equivalent of mounted)
onMounted(() => {
  // Function to determine device type or screen size
  const isMobile = window.innerWidth <= 768; // Adjust this breakpoint as needed
  const isTablet = window.innerWidth > 768 && window.innerWidth <= 1024;

  // Set the threshold based on device type
  let threshold = 0.5; // Default for desktop
  if (isMobile) {
    threshold = 0.2; // Less threshold for mobile
  } else if (isTablet) {
    threshold = 0.3; // Adjust for tablet
  }

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        isVisible.value = true; // Update reactive isVisible to trigger reactivity
        observer.unobserve(entry.target); // Stop observing once it's visible
      }
    });
  }, {
    threshold: threshold, // Trigger when 50% of the element is visible
    rootMargin: '0px 0px -100px 0px' // Adjust the margin to trigger earlier/later
  });

  // Observe the parallax container
  // observer.observe(document.querySelector('.v-parallax'));
  observer.observe(document.querySelector('.card-container'));
});
</script>


<style scoped>
.v-parallax__image {
  height: 100% !important;
}

.card-container {
  margin-top: 6%;
}
</style>