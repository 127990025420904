<template>
  <div id="use-cases-container">
    <div id="test">
      <div
          ref="useCaseRef"
          v-for="(item, index) in useCases"
          :key="index"
      >
        <v-sheet
            :style="{
              backgroundImage: `url(${item.background})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              textAlign: `${item.align}`
            }"
            :class="[
              `use-case-${index}`,
              { hidden: !isVisible[index] },
              isVisible[index] ? (index % 2 === 0 ? 'slide-enter-active' : 'slide-enter-right-active') : ''
            ]"
            elevation="16"
            rounded="xl"
            class="mx-auto"
            height="700"
            width="100%"
        >
          <div :class="`text-container container-${index}`">
            <h1 :style="{ color: item.color }">{{ item.title }}</h1>
<!--            <h2 :style="{ color: item.color }">{{ item.description }}</h2>-->
          </div>
        </v-sheet>
      </div>
    </div>
  </div>
</template>

<script>
import modaImage from '@/assets/useCasesPage/moda.jpg';
import sportImage from '@/assets/useCasesPage/sport.jpg';
import videogameImage from '@/assets/useCasesPage/games.jpg';
import medicaleImage from '@/assets/useCasesPage/medical.jpg';

export default {
  name: 'UseCases',
  data() {
    return {
      useCases: [
        {
          color: '#ffffff',
          align: 'left',
          title: 'Fashion',
          description: 'Scrivere qualcosa di bello per quanto riguarda la moda.',
          background: modaImage,
        },
        {
          color: '#000000',
          align: 'right',
          title: 'Sport',
          description: 'Scrivere qualcosa di bello per quanto riguarda lo sport.',
          background: sportImage,
        },
        {
          color: '#ffffff',
          align: 'left',
          title: 'Gaming',
          description: 'Scrivere qualcosa di bello per quanto riguarda i videogames.',
          background: videogameImage,
        },
        {
          color: '#000000',
          align: 'right',
          title: 'Medical',
          description: 'Scrivere qualcosa di bello per quanto riguarda medicale.',
          background: medicaleImage,
        },
      ],
      isVisible: Array(4).fill(false) // Store visibility state for each v-sheet
    };
  },
  mounted() {
    this.setupIntersectionObserver();
  },
  methods: {
    setupIntersectionObserver() {
      const options = {
        threshold: 0.5, // Trigger when 50% of the v-sheet is visible
      };

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          const index = entry.target.getAttribute('data-index');
          if (entry.isIntersecting) {
            this.$set(this.isVisible, index, true); // Make the v-sheet visible
          }
        });
      }, options);

      // Observe each v-sheet
      this.$refs.useCaseRef.forEach((useCase, index) => {
        useCase.setAttribute('data-index', index);
        observer.observe(useCase);
      });
    },
  },
};
</script>

<style scoped>
#use-cases-container {
  background-color: black;
  padding: 8%;
}

h1 {
  margin-bottom: 3.5%;
  font-size: 4em;
}

.text-container {
  padding: 5%;
  width: 50%;
}

.v-sheet {
  margin-bottom: 10%;
}

.container-1, .container-3 {
  float: right;
}

.use-case-3 {
  margin-bottom: 0;
}

/* Slide-in animations */
@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-enter-active {
  animation: slideInLeft 1s ease-out forwards;
}

.slide-enter-right-active {
  animation: slideInRight 1s ease-out forwards;
}

.hidden {
  opacity: 0;
  transform: translateX(-100%);
}

@media only screen and (max-width: 600px) {
  #use-cases-container {
    margin-top: 15% !important;
  }
  h1 {
  margin-bottom: 3.5%;
  font-size: 2.2em;
}
}

@media only screen and (min-width: 600px) {
  #test {
    margin-top: 8% !important;
  }
  h1 {
  margin-bottom: 3.5%;
  font-size: 2.2em;
}
}

@media only screen and (min-width: 768px) {
  #test {
    margin-top: 8% !important;
  }
  h1 {
  margin-bottom: 3.5%;
  font-size: 2.3em;
}
}

@media only screen and (min-width: 992px) {
  #test {
    margin-top: 4% !important;
  }
  h1 {
  margin-bottom: 3.5%;
  font-size: 2.5em;
}
}

@media only screen and (min-width: 1200px) {
  #test {
    margin-top: 0 !important;
  }
  h1 {
  margin-bottom: 3.5%;
  font-size: 2.7em;
}
}

@media only screen and (min-width: 2000px) {
  #test {
    margin-top: 0 !important;
  }

  .v-sheet {
    height: 1000px !important;
  }
  h1 {
  margin-bottom: 3.5%;
  font-size: 3em;
}
}
</style>
