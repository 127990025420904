import VueRouter from 'vue-router';
import Vue from "vue";
import Home from './components/Home'
import EffingoUseCasesCard from './components/EffingoUseCasesCard.vue'
import Customers from './components/Customers'
import Contacts from './components/Contacts'
import AboutUs from './components/EffingoAboutUs.vue'
import WebsiteConfigurator from './components/WebsiteConfigurator'


Vue.use(VueRouter)
export default new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'Home',
            component: Home
        },
        {
            path: '/usecases',
            name: 'UseCases',
            component: EffingoUseCasesCard,
        },
        {
            path: '/clienti',
            name: 'Customers',
            component: Customers
        },
        {
            path: '/about',
            name: 'About Us',
            component: AboutUs
        },
        {
            path: '/configuratore',
            name: 'Configurator',
            component: WebsiteConfigurator
        }
    ],
    scrollBehavior(to, from, savedPosition) {
        // If savedPosition is available, use it (e.g., when using browser back button)
        if (savedPosition) {
            return savedPosition;
        } else {
            // Otherwise, scroll to the top of the page for each new route
            return {x: 0, y: 0};

            // // Ensure immediate snap to the top
            // return new Promise((resolve) => {
            //     setTimeout(() => {
            //         window.scrollTo(0, 0); // Use window.scrollTo to disable smooth scrolling
            //         resolve({x: 0, y: 0});
            //     }, 0);
            // });
        }
    }
})




