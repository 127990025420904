import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"about-container"}},[_c('div',{attrs:{"id":"top-container"}},[_c('img',{attrs:{"id":"image-logo","src":require("../assets/Big_logo_effingo.png"),"alt":"Responsive image"}}),_c('h1',[_vm._v("Leading Technology in 3D Body Scanning")]),_vm._l((_vm.aboutList),function(item,index){return _c('div',{key:index,ref:"aboutRef",refInFor:true},[_c(VSheet,{staticClass:"mx-auto",class:[
            `about-us-${index}`
          ],attrs:{"elevation":"16","rounded":"xl","height":"auto","width":"100%","color":"#666666"}},[_c('div',{class:`text-container container-${index}`},[_c('h1',{style:({ color: item.color })},[_vm._v(_vm._s(item.title))]),_c('div',{attrs:{"id":"container-divider"}},[_c(VDivider)],1),_c('h2',{style:({ color: item.color }),domProps:{"innerHTML":_vm._s(item.description)}})])])],1)})],2),_c(VCarousel,{attrs:{"hide-delimiters":"","cycle":""},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},_vm._l((_vm.itemCard),function(itemCard,indexCard){return _c(VCarouselItem,{key:indexCard},[_c(VCard,{staticClass:"mx-auto card-container",style:({
            backgroundImage: `url(${itemCard.background})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }),attrs:{"max-width":"800","height":"100%","href":itemCard.link,"target":"_blank"}},[_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"text-content"},[_c(VCardTitle,{staticClass:"card-title",style:({ color: itemCard.color })},[_vm._v(_vm._s(itemCard.title))]),_c(VCardSubtitle,{staticClass:"card-subtitle",style:({ color: itemCard.color })},[_vm._v(_vm._s(itemCard.description)+" ")])],1)])])],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }