<template>
  <div id="customer-page-container">
    <div id="customers-container">
      <h1 align="center">Clienti</h1>
      <b-row class="row">
        <!-- <b-col v-for="(customer, index) in customers" :key="index" class="image-col" cols="12"  sm="12" md="4" lg="4" xl="3" align="center" > -->
        <b-col v-for="(customer, index) in customers" :key="index" class="image-col" cols="12"  sm="12" md="12" lg="6" xl="6" align="center" >
          <img :src="require(`../assets/customers/${customer.imageUrl}`)">
        </b-col>
      </b-row>
    </div>

    <div id="partners-container">
      <h1 align="center">Partners</h1>
      <b-row class="row">
        <!-- <b-col v-for="(partner, index) in partners" :key="index" class="image-col" cols="12"  sm="12" md="4" lg="4" xl="3" align="center" > -->
        <b-col v-for="(partner, index) in partners" :key="index" class="image-col" cols="12"  sm="12" md="12" lg="6" xl="6" align="center" >
          <img :src="require(`../assets/partners/${partner.imageUrl}`)">
        </b-col>        
      </b-row>

    </div>
    
  </div>
</template>

<script>
export default {
  name: 'Customers',
    data: () => ({
    customers: [
      {
        "imageUrl":"mohvadologo.png",
      },
      {
        "imageUrl":"carhellologo.png",
      }
    ],

    partners: [
      {
        "imageUrl":"carhellologo.png",
      },
      {
        "imageUrl":"mohvadologo.png",
      }
    ],
})

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  h1{
    margin:2% ;
    color:#10bccd;
  }

  img{
      width: 200px;
      margin: 5%;
  }

  @media only screen and (min-width: 1025px) {
    img{
      width: 300px;
    }

    .image-col > img{
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
    }

    .image-col:hover{
      img{
        -webkit-filter: none;
        filter: none;
        transition-duration: 2s;
      }
    }
  }
</style>
