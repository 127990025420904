<template>
  <v-sheet
      class="mx-auto pa-8 pa-sm-5 pa-xs-5"
      rounded="xl"
      width="300"
      height="230"
      :class="{'is-visible': isVisible}"
      :style="{ 'transition-delay': `${index * 0.6}s` }"
  >
    <h1><b-icon :icon="icon"></b-icon></h1>

    <h4>{{title.toUpperCase()}}</h4>

    <h5>{{description}}</h5>
  </v-sheet>
</template>

<script setup>

import { onMounted } from 'vue';

// Define props directly in <script setup>
const props = defineProps({
  isMobile: Boolean,
  isVisible: Boolean,
  index: Number,
  icon: String,
  title: String,
  description: String,
});

// You can use lifecycle hooks like this
onMounted(() => {
  console.log(props.isVisible); // Access props here
});
</script>

<style scoped>
h1, h4 {
  text-align: center;
  color: #0088cc;
}
.v-sheet {
  //margin-top: 7%;
  //padding: 8% !important;
  text-align: center;
  opacity: 0;
  //transform: translateX(-100%);
  transition: opacity 0.5s ease, transform 0.5s ease;
  visibility: hidden; /* Inizialmente nascosto */
}

.is-visible {
  opacity: 1;
  //transform: translateX(0);
  visibility: visible; /* Quando l'elemento diventa visibile */
}

@media only screen and (max-width: 350px) {
  .v-sheet {
    width: 100% !important;
    height: 100% !important;
  }
}
</style>