<template>
  <div id="footer-container">
    <b-row>
      <b-col class="col" cols="12" sm="4" align="center">
        <div class="container">
          <img
              id="logo"
              src="../assets/logo.svg"
              alt="Responsive image"
          />
          <p>Copyright Ⓒ 2024. All rights reserved</p>
          <!--          <p>P.IVA 52165413254</p>-->
        </div>
      </b-col>
      <b-col class="col" cols="12" sm="4" align="center">
        <div class="container">
          <h4>Contacts</h4>

          <p><a href="mailto:info@effingo.me">info@effingo.me</a></p>
          <!--            <p><a href="tel:1231232323">+39 1231232323</a></p>-->
        </div>
      </b-col>
      <b-col class="col" cols="12" sm="4" align="center">
        <div class="container">
          <h4>Follow Us</h4>
          <Socials></Socials>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import Socials from "./Socials";
import {checkIsMobile} from "../utils"

export default {
  components: {
    Socials,
  },
  mounted() {
    if (checkIsMobile()) document.getElementById("footer-container").style.backgroundImage = "none";
  }
};
</script>
<style lang="scss" scoped>
#footer-container {
  width: 100%;
  // padding: 2% 0px; 
  background: #000000; /* For browsers that don't support gradients */
  //background-image: -webkit-linear-gradient(315deg, #000000 60%, #10bccd);
}

.col {
  padding: 2%;
  margin: 0 auto;
  color: white;
}

a {
  color: white !important;
}

#logo {
  width: 80%;
  margin-bottom: 2%;
}

h4 {
  margin-bottom: 5%;
}

@media only screen and (min-width: 600px) {
  #logo {
    margin-top: -5%;
  }
}

@media only screen and (max-width: 600px) {
  #footer-container {
    padding-bottom: 5%;
  }
}
</style>