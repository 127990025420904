import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"use-cases-container"}},[_c('div',{attrs:{"id":"test"}},_vm._l((_vm.useCases),function(item,index){return _c('div',{key:index,ref:"useCaseRef",refInFor:true},[_c(VSheet,{staticClass:"mx-auto",class:[
              `use-case-${index}`,
              { hidden: !_vm.isVisible[index] },
              _vm.isVisible[index] ? (index % 2 === 0 ? 'slide-enter-active' : 'slide-enter-right-active') : ''
            ],style:({
              backgroundImage: `url(${item.background})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              textAlign: `${item.align}`
            }),attrs:{"elevation":"16","rounded":"xl","height":"700","width":"100%"}},[_c('div',{class:`text-container container-${index}`},[_c('h1',{style:({ color: item.color })},[_vm._v(_vm._s(item.title))])])])],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }